import LayoutSource from "./LayoutSource";
import {push} from "./Routing";
import {FINISH} from "./OrdersReducer";

export const LAYOUT_LOADING_START = 'layout/LAYOUT_LOADING_START';
export const LAYOUT_LOADING_END = 'layout/LAYOUT_LOADING_END';
export const LOGIN = 'layout/LOGIN';
export const LOGIN_FAIL = 'layout/LOGIN_FAIL';
export const LOGOUT = 'layout/LOGOUT';
export const SET_UUID = 'layout/SET_UUID';
export const SET_MESSAGE = 'layout/SET_MESSAGE';
export const ADD_MESSAGE = 'layout/ADD_MESSAGE';
export const LAYOUT_LOGOUT_TIMER = 'layout/LAYOUT_LOGOUT_TIMER';

const initialState = {
    isLoading: false,
    user: null,
    uuid: null,
    workerId: null,
    messages: [],
    logoutTimer: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LAYOUT_LOADING_START:
            return {
                ...state,
                isLoading: true
            };

        case LAYOUT_LOADING_END:
            return {
                ...state,
                isLoading: false
            };

        case LOGIN:
            return {
                ...state,
                user: action.user
            };

        case LOGIN_FAIL:
            return {
                ...state,
                messages: [
                    {
                        kind: 'warning',
                        text: 'Błąd logowania.',
                        beep: false,
                    }
                ]
            };

        case
        LOGOUT:
            return {
                ...state,
                user: null,
                uuid: null,
                workerId: null,
                messages: [],
                expire: null,
            };

        case
        SET_UUID:
            return {
                ...state,
                uuid: action.uuid,
                workerId: action.workerId
            };

        case
        SET_MESSAGE:
            return {
                ...state,
                messages: [
                    {
                        kind: action.kind,
                        text: action.text,
                        beep: action.beep,
                    }
                ]
            };
        case
        ADD_MESSAGE:
            let newMessage = {
                kind: action.kind,
                text: action.text,
                beep: action.beep,
            };

            let messages = [...state.messages];
            console.log(newMessage);
            return {
                ...state,
                messages: messages
            };

        case LAYOUT_LOGOUT_TIMER:
            return {
                ...state,
                logoutTimer: action.seconds
            };

        default:
            return state;
    }
};

export const login = (code) => dispatch => {
    dispatch({type: SET_MESSAGE, kind: 'load', text: 'Trwa logowanie do aplikacji...', beep: false});
    LayoutSource.getUser(code).then((result) => {
        if (result.status === 200) {
            dispatch({type: LOGIN, user: result.data});
            if (result.data.route === '/app/qr') {
                dispatch({type: SET_MESSAGE, kind: 'info', text: 'Zeskanuj kod QR ze zlecenia.', beep: false});
            }
            push(result.data.route)(dispatch);
        } else {
            dispatch({type: LOGIN_FAIL});
            push('/')(dispatch);
        }
    }).catch(() => {
        dispatch({type: LOGIN_FAIL});
        push('/')(dispatch);
    });
};

export const logout = () => dispatch => {
    dispatch({type: FINISH});
    dispatch({type: LOGOUT});
    window.location.href = '/';
};

export const setUuid = (qrCode) => dispatch => {
    let jsonObj = JSON.parse(qrCode);
    let uuid = jsonObj.id;
    let workerId = jsonObj.w;

    if (uuid && uuid.length === 40) {
        dispatch({type: SET_UUID, uuid: uuid, workerId: workerId});
        push('/app/orders')(dispatch);
    } else {
        dispatch({type: SET_MESSAGE, kind: 'error', text: 'Nieprawidłowy kod!', beep: false});
    }
};

export const setLogoutTimer = (seconds) => {
    return dispatch => {
        dispatch({type: LAYOUT_LOGOUT_TIMER, seconds});
    };
};

export const setMessage = (kind, text, beep) => {
    return dispatch => {
        dispatch({type: SET_MESSAGE, kind: kind, text: text, beep: beep});
    };
};

export const addMessage = (kind, text, beep) => {
    return dispatch => {
        dispatch({type: ADD_MESSAGE, kind: kind, text: text, beep: beep});
    };
};

export const clearMessages = () => {
    return dispatch => {
        dispatch({type: SET_MESSAGE, kind: null, text: null, beep: false});
    };
};

