import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Layout from '../Components/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'Static/css/App.css';
import OnScan from "../Utility/OnScan";
import IdleTimer from 'react-idle-timer';
import {bindActionCreators} from "redux";
import {logout, setLogoutTimer, setUuid} from "../Modules/LayoutReducer";

class App extends React.Component {

    constructor(props) {
        super(props);

        this.clearIdle = this.clearIdle.bind(this);
        this.onIdle = this.onIdle.bind(this);

        this.idle = null;
    }

    componentDidMount() {
        OnScan.getInstance().addListener('setUuid', /^{"id":"(.*),"w":([0-9]+)}$/, (qrCode) => this.props.setUuid(qrCode));
    }

    componentWillUnmount() {
        this.clearIdle();
    }

    clearIdle() {
        if (this.idle !== null) {
            window.clearInterval(this.idle);
            this.idle = null;
            this.props.setLogoutTimer(null);
        }
    }

    onIdle() {
        this.clearIdle();

        if (this.props.user) {
            this.idle = window.setInterval(() => {
                const time = this.props.logoutTimer === null ? 20 : Number(this.props.logoutTimer) - 1;

                if (time < 1) {
                    this.props.logout();
                } else {
                    this.props.setLogoutTimer(time);
                }
            }, 1000);
        } else {
            this.idle = window.setInterval(() => {}, 15 * 60 * 1000);
        }
    }

    render() {
        return <IdleTimer
            element={document}
            onActive={this.clearIdle}
            onIdle={this.onIdle}
            timeout={1000 * 60 * 15}
        >
        <Layout {...this.props}/>
        </IdleTimer>;
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({logout, setUuid, setLogoutTimer}, dispatch);

const mapStateToProps = state => ({
    isLoading: state.LayoutReducer.isLoading,
    user: state.LayoutReducer.user,
    messages: state.LayoutReducer.messages,
    logoutTimer: state.LayoutReducer.logoutTimer,
    expire: state.LayoutReducer.expire,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
