import _ from 'lodash';

class OnScan {

    static instance = null;
    listeners = [];
    timeout = 800;

    /**
     * @returns {OnScan}
     */
    static getInstance() {
        if (OnScan.instance == null) {
            OnScan.instance = new OnScan();
        }

        return this.instance;
    }

    constructor() {
        this.reset();

        window.addEventListener('keypress', (e) => this.onKeyPress(e));
        // Helena Android hook
        window.scanner = (code) => this.scan(code);
        window.code = (code) => this.scan(code);
    }

    addListener(id, test, callback) {
        this.listeners.push({
            id: id,
            test: test,
            callback: callback,
        })
    }

    removeListener(id) {
        this.listeners = _.filter(this.listeners, function (listener) {
            return listener.id !== id;
        })
    }

    setTimeout(timeout) {
        this.timeout = timeout || this.timeout;
    }

    onKeyPress(e) {
        if (!e.target.className.includes('form-control')) {
            if (e.which === 13) { // enter
                this.finish();
            } else {
                if (e.key && e.key.length === 1) {
                    this.chars.push(e.key);
                }
            }

            if (this.pressed === false && this.chars.length > 0) {
                this.idTimeout = setTimeout(this.finish, this.timeout);
                this.pressed = true;
            }
        }
    }

    scan(code) {
        for (let idx = this.listeners.length - 1; idx >= 0; idx--) {
            let listener = this.listeners[idx];
            if (listener.callback && (!listener.test || listener.test.test(code))) {
                listener.callback(code);

                break;
            }
        }
    }


    finish() {
        if (this.chars) {
            if (this.chars.length >= 8 && this.chars.length < 30) {
                let code = _.trim(this.chars.join('')).toLocaleUpperCase();
                this.scan(code);
            } else {
                let code = _.trim(this.chars.join(''));
                this.scan(code);
            }
        }
        if (this.reset) {
            this.reset();
        }
    }

    reset() {
        if (this.idTimeout != null) {
            clearTimeout(this.idTimeout);
            this.idTimeout = null;
        }

        this.pressed = false;
        this.chars = [];
    }

    destroy() {
        window.removeEventListener('keyup', this.onKeyUp);
        this.reset();
    }
}

export default OnScan;
